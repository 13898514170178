.sog-container {
    padding: 10px 10%;
}

.sog-pic-1, .sog-pic-2 {
    text-align: center;
    margin: 20px 0;
}

.sog-pic-1 img, .sog-pic-2 img {
    border-radius: 10px;
}

.sog-container h1 {
    font-size: 45px;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}

.sog-container p {
    font-size: 20px;
    line-height: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: auto;
}

/****** Responsiveness ******/

@media screen and (max-width: 800px) {
    .sog-pic-1 img, .sog-pic-2 img {
        width: 70%;
    }

    .sog-container h1 {
        font-size: 35px;
    }

    .sog-container p {
        font-size: 15px;
    }

    
}