/****** Header Section ******/

.header-section-blg {
    min-height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url(./Images/blog.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
}

.title-blg {
    font-size: 55px;
    color: white;
    margin-top: 14%;
    text-align: center;
}

.text-box-blg {
    text-align: center;
}

.btn-blg {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 500px;
    padding: 20px 45px;
    font-size: 20px;
    background: transparent;
    cursor: pointer;
    margin: 40px;
}

.btn-blg:hover {
    border: 1px solid red;
    background: red;
    transition: 1s;
}

/****** Newsletter Section ******/

.blog-posts {
    margin-bottom: 50px;
}

.newsletter-sect h1 {
    text-align: center;
    font-size: 45px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.newsletter-sect p {
    text-align: center;
    font-size: 20px;
    padding-bottom: 20px;
}

.form-box {
    text-align: center;
}

.form-box input {
    border: solid red;
    border-width: 8px;
    border-radius: 5px;
    height: 50px;
    width: 400px;
    font-size: 20px;
    font-style: italic;
    padding-left: 25px;
    color: black;
}

.form-box button {
    position: relative;
    border: red;
    background-color: red;
    height: 50px;
    width: 205px;
    border-radius: 5px;
    font-size: 20px;
    right: 7px;
    color: #fff;
    outline: none;
    cursor: pointer;
}

/****** Blogs Section ******/

.blog-post{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.blog {
    width: 345px;
    height: 500px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin: 35px;
    transition: 0.5s ease;
    cursor: pointer;
    border-color: black;
    border-width: 5px;
    border-style: solid;
}

.blog:hover {
    transform: scale(1.1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.blog img {
    width: 100%;
    height: 35%;
}

.blog-post h3 {
    margin-left: 20px;
    margin-top: 10px;
}

.blog-post h2 {
    font-size: 27px;
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center;
    color: black;
}

.blog-post p {
    font-size: 20px;
    padding: 8px 16px;
    border-radius: 4px;
    text-align: center;
    color: black;
    margin-bottom: 20px;
}

.read-more-btn {
    text-align: center;
}

.blog-post a {
    color: red;
    font-size: 20px;
    
    position: relative;
    text-decoration: none;
}

.expand-btn, .hide-btn {
    text-align: center;
}

.expand-btn button, .hide-btn button {
    display: inline-block;
    text-decoration: none;
    color: red;
    border: 1px solid red;
    border-radius: 500px;
    padding: 20px 45px;
    font-size: 20px;
    position: relative;
    background: transparent;
    cursor: pointer;
}

.expand-btn button:hover, .hide-btn button:hover {
    border: 1px solid red;
    background: red;
    transition: 1s;
    color: #fff;
}

/****** Responsiveness ******/

@media screen and (max-width: 800px) {

    .header-section-blg {
        min-height: 80vh;
    }

    .title-blg {
        font-size: 35px;
        margin-top: 40%;
        margin-bottom: 20px;
    }

    .btn-blg {
        padding: 15px 25px;
        font-size: 15px;
    }

    .newsletter-sect h1 {
        font-size: 35px;
    }

    .newsletter-sect p {
        font-size: 18px;
    }

    .form-box input {
        width: 200px;
        font-size: 15px;
    }

    .form-box button {
        width: 100px;
        font-size: 15px;  
    }

    .blog {
        height: 450px;
    }

    .blog-post h2 {
        font-size: 20px;
    }

    .blog-post p {
        font-size: 18px;
    }

    .expand-btn a, .hide-btn a {
        padding: 15px 35px;
        font-size: 15px;
    }

    .expand-btn button, .hide-btn button  {
        padding: 15px 35px;
        font-size: 15px;
    }


}