/****** Header Section ******/

.header-glo {
    min-height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url(./Images/global.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
}

.global-head h1 {
    font-size: 55px;
    color: white;
    margin-top: 14%;
    text-align: center;
}

/****** Middle Section ******/

.middle-glo h1 {
    text-align: center;
    font-size: 45px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.middle-glo p {
    text-align: center;
    font-size: 20px;
    width: 50%;
    padding-bottom: 20px;
    margin: auto;
}

.line-align hr {
    margin: auto;
}

.line-glo {
    width: 70%;
    margin-top: 40px;
    margin-bottom: 40px;
    height: 4px;
    background: black;
}

/****** Global Section ******/

.logo {
    display: flex;
    margin: 40px 15%;
}

.half-align {
    margin: 0 70px;
}

.logo img {
    border-radius: 10px;
}

.logo h2 {
    text-align: center;
    
}

.logo button, .logo a {
    display: inline-block;
    background-color: #fff;
    text-decoration: none;
    color: black;
    border: 1px solid black;
    border-radius: 500px;
    padding: 10px 35px;
    font-size: 15px;
    cursor: pointer;
    margin-top: 10px;
}

.btn-glo:hover {
    border: 1px solid black;
    background: black;
    transition: 1s;
    color: #fff;
}

/****** Responsiveness ******/

@media screen and (max-width: 800px) {
    .header-glo {
        min-height: 80vh;
    }

    .header-glo h1 {
        font-size: 30px;
        margin-top: 40%;
    }

    .middle-glo h1 {
        font-size: 35px;
    }

    .middle-glo p {
        font-size: 15px;
        width: 80%;
        margin: auto;
    }

    .logo {
        margin: 0 10px;
        text-align: center;
    }

    .half-align {
        margin: auto;
    }

    .logo img {
        width: 60%;
        height: 50%;
    }

    .logo h2 {
        font-size: 10px;
    }

    .logo button, .logo a {
        padding: 10px 35px;
        font-size: 10px;
        margin: 20px 0;
    }

}


