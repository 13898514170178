nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

nav ul li {
    display: inline-block;
    list-style: none;
    margin: 10px 20px;
}

nav ul li a {
    color: white; 
    text-decoration: none;
    font-size: 18px;
    position: relative;
    cursor: pointer;
}

nav ul li a::after {
    content: '';
    width: 0;
    height: 3px;
    background: red;
    position: absolute;
    left: 0;
    bottom: -6px;
    transition: 0.5s;
}

.logo-bottom {
    cursor: pointer;
}

.container-footer {
    padding: 10px 3%;
}

.footer {
    background-color: black;
    color: white;
    padding-bottom: 20px;
    padding-top: 20px;
}

.footer-line {
    width: 100%;
    height: 4px;
    background: white;
    margin-top: 15px;
    margin-bottom: 15px;
}

.line {
    margin-top: 20px;
}

/**********Responsive section**********/
@media screen and (max-width: 800px) {
    .logo-bottom {
        width: 350px;
        margin: auto;
    }
    
    .line {
        margin-top: 10px;
    }

    .footer-line {
        margin: auto;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}