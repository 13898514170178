/****** Header Section ******/

.header-section-sv {
    min-height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url(./Images/serve.JPG);
    background-position: center;
    background-size: cover;
    position: relative;
}

.text-box-sv h1 {
    font-size: 55px;
    color: white;
    margin-top: 14%;
    text-align: center;
}

.serve-btns {
    text-align: center;
}

.btn-sv {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 500px;
    padding: 20px 45px;
    font-size: 20px;
    background: transparent;
    cursor: pointer;
    margin: 40px;
}

.btn-sv:hover {
    border: 1px solid red;
    background: red;
    transition: 1s;
}

/****** Responsiveness ******/

@media screen and (max-width: 800px) {
    .header-section-sv {
        min-height: 80vh;
    }

    .text-box-sv h1{
        font-size: 35px;
        margin-top: 40%;
        margin-bottom: 20px;
    }

    .btn-sv {
        padding: 15px 25px;
        font-size: 15px;
        font-size: 15px;
        margin: 12px;
        
    }
}