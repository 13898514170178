/****** Header Section ******/

.header-section-eq {
    min-height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url(./Images/equipment.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
}

.header-section-eq h1 {
    font-size: 55px;
    color: white;
    padding-top: 14%;
    text-align: center;
}

/****** Middle Section ******/

.middle-section-eq h1 {
    text-align: center;
    font-size: 45px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.middle-section-eq p {
    text-align: center;
    font-size: 20px;
    padding-bottom: 20px;
    margin: auto;
    width: 80%;
}

.equip-align {
  text-align: center;
}

.btn-eqi {
  display: inline-block;
  text-decoration: none;
  color: red;
  border: 1px solid red;
  border-radius: 500px;
  padding: 20px 45px;
  font-size: 20px;
  cursor: pointer;
}

.btn-eqi:hover {
  border: 1px solid red;
  background: red;
  transition: 1s;
  color: #fff;
}

/****** Picture section ******/

.item-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.item img {
  width: 100%;
  display: block;
}

.item {
  width: 425px;
  height: 270px;
  border-radius: 8px;
  overflow: hidden;
  margin: 30px;
  cursor: pointer;
  position: relative;
  border-color: black;
  border-width: 5px;
  border-style: solid;
}

.item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  transition: background-color 0.5s ease;
}

.item:hover::before {
  background-color: rgba(0, 0, 0, 0.8);
}


.description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 30px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  display: none;
  transition: opacity 0.5s ease;
}

.item:hover .description {
  display: block;
  opacity: 1;
  
}

.equip-align-back {
  text-align: center;
  margin-bottom: 30px;
}

/****** Responsiveness ******/

@media screen and (max-width: 800px) {
  .header-section-eq {
    min-height: 80vh;
  }

  .header-section-eq h1 {
    font-size: 35px;
    padding-top: 40%;
  }

  .middle-section-eq h1 {
    font-size: 35px;
  }

  .middle-section-eq p {
    font-size: 15px;
    width: 100%;
  }

  .btn-eqi {
    padding: 15px 35px;
    font-size: 15px;
  }

  .item img {
    height: 100%;
  }

  .item {
    height: 220px;
  }
}

