/****** Header Section ******/

.header-section-cont {
    min-height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url(./Images/contact.JPG);
    background-position: center;
    background-size: cover;
    position: relative;
}

.contact-head h1 {
    font-size: 55px;
    color: white;
    margin-top: 14%;
    text-align: center;
}

/****** Contact Section ******/

.contact-dub-info {
    display: flex;
    justify-content: center;
    margin: 40px 0;
}

.contact-det, .contact-fm {
    display: inline-block;
    margin: 0 80px;
}

.contact-det h1 {
    font-size: 55px;
    padding-bottom: 20px;
    
}

.contact-det h2 {
   margin: 20px 0; 
    
}

.phone-num, .email {
    font-size: 25px;
    font-weight: 600;
}

.fa-phone, .fa-envelope {
    color: red;
    font-size: 30px;
}


form input, form textarea {
    width: 100%;
    border: 0;
    outline: none;
    background: lightgray;
    padding: 15px;
    margin: 15px 0;
    color: black;
    font-size: 20px;
    border-radius: 6px;
}

.contact-fm button {
    height: 60px;
    width: 205px;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 20px;
    border: red;
    background-color: red;
    color: #fff;
}

/****** Responsiveness ******/

@media screen and (max-width: 800px) {
    .header-section-cont {
        min-height: 80vh;
    }

    .contact-head h1 {
        font-size: 30px;
        margin-top: 40%;
    }

    .contact-det h1 {
        font-size: 35px;
        margin: 0px;
    }

    .contact-det h2 {
        font-size: 20px;
        margin: 15px 0;
    }

    .phone-num, .email {
        font-size: 15px;
    }

    .fa-envelope, .fa-phone {
        font-size: 25px;
    }

    .contact-dub-info {
        display: inline;
    }

    .contact-fm {
        margin: 30px;
    }

}