.upper {
    background-color: black;
}

.blog-container {
    padding: 10px 10%;
}

.blog-links {
    color: red;
}

.blog-container h1 {
    font-size: 45px;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 55px;
}

.blog-container h5 {
    font-size: 20px;
    padding-bottom: 30px;
}

.blog-container img {
    margin-bottom: 30px;
    border-radius: 20px;
}

.blog-container p {
    font-size: 20px;
    line-height: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: auto;
}

.bold {
    font-weight: bold;
}

.italics {
    font-style: italic;
    color: gray;
}

.blog-p-back-btn {
    text-align: center;
}

.back-btn {
    display: inline-block;
    text-decoration: none;
    color: red;
    border: 1px solid red;
    border-radius: 500px;
    padding: 20px 45px;
    font-size: 20px;
    position: relative;
    background: transparent;
    cursor: pointer;
    margin-bottom: 30px;
    margin-top: 30px;
}

.back-btn:hover {
    border: 1px solid red;
    background: red;
    transition: 1s;
    color: #fff;
}

.second-pic-blog {
    margin-top: 30px;
}

.smaller-img {
    width: 60%;
}

@media screen and (max-width: 800px) {
    .blog-container h1 {
        font-size: 30px;
        line-height: 40px;
    }

    .blog-container p {
        font-size: 15px;
    }

    .blog-container img {
        width: 90%;
    }

    .back-btn {
        padding: 15px 35px;
        font-size: 15px;
    }
}