/****** Header Section ******/

.header-section-ab {
    min-height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url(./Images/about.jpg);
    background-position: center;
    background-size: cover;
    position: relative;

}

.title-hm-ab {
    font-size: 55px;
    margin-top: 14%;
    color: white;
    text-align: center;
}

.abt-btn-nav {
    text-align: center;
}

.btn-abt {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 500px;
    padding: 20px 45px;
    font-size: 20px;
    background: transparent;
    cursor: pointer;
    margin: 40px;
}

.btn-abt:hover {
    border: 1px solid red;
    background: red;
    transition: 1s;
}

/****** Mission Statement Section ******/

.miss-stat-h1 {
    text-align: center;
    font-size: 45px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.abt-align p {
    text-align: center;
    font-size: 20px;
    width: 52%;
    padding-bottom: 20px;
    margin: auto;
}

.abt-align hr {
    width: 65%;
    height: 4px;
    margin: auto;
    background: black;
    margin-top: 2px;
    margin-bottom: 25px;
}

/****** Founders Section ******/

.tact-found-h1 {
    text-align: center;
    font-size: 45px;
    padding-top: 10px;
    padding-bottom: 40px;
}

.abt-boxes {
    margin: auto;
    display: flex;
    
}

.img-box img{
    width: 90%;
    border-style: solid;
    border-width: 10px;
    border-color: black;
    border-radius: 20px;
}

.bio-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 800px;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-color: black;
  border-width: 8px;
}

.bio-box p {
    font-size: 18px;
    word-spacing: 5px;
    padding: 20px;
    color: black;
    text-align: center;
}

.bio-box h1 {
    padding-bottom: 15px;
    color: black;
    font-size: 30px;
}


/****** Vision Section ******/

.vision-h1 {
    text-align: center;
    font-size: 45px;
    margin-top: 40px;
    color: black;
}

.vision-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .vs-card {
    width: 260px;
    border-radius: 20px;
    overflow: hidden;
    border-color: black;
    border-style: solid;
    border-width: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin: 40px;
    transition: 0.5s ease;
    cursor: pointer;
    padding: 25px;
  }
  
  .vision-cards h2 {
      text-align: center;
      margin-bottom: 10px;
      color: black;
      font-size: 25px;
  }
  
  .vision-cards p {
      text-align: center;
      padding-bottom: 20px;
      color: black;
  }

  .icon-align {
    text-align: center;
  }
  
  .fa-book-bible, .fa-users, .fa-church, .fa-earth-americas {   
      font-size: 95px;
      padding-top: 30px;
      padding-bottom: 20px;
      color: black;
  }
  
  .vs-card:hover {
      transform: scale(1.1);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }

/****** Believe & Celebrate Section ******/

.bel-cel {
    padding-bottom: 30px;
}

.bel-cel-h1 {
    text-align: center;
    font-size: 45px;
    padding-top: 20px;
    padding-bottom: 40px;
    color: black;
}

.bel-cel-align {
    text-align: center;
}

.btn-abt2 {
  display: inline-block;
  text-decoration: none;
  background-color: #fff;
  color: red;
  border: 1px solid red;
  border-radius: 500px;
  padding: 20px 45px;
  font-size: 20px;
  cursor: pointer;
  margin: 20px;
}

.btn-abt2:hover {
  border: 1px solid red;
  background: red;
  transition: 1s;
  color: #fff;
}


/****** Responsiveness ******/

@media screen and (max-width: 800px) {

    .header-section-ab {
        min-height: 80vh;
      }

    .title-hm-ab {
        font-size: 35px;
        padding-top: 40%;
    }

    .btn-abt {
        padding: 15px 35px;
        font-size: 15px;
        margin-top: 10%;
    }

    .miss-stat-h1 {
        font-size: 38px;
    }

    .miss-stat p {
        font-size: 15px;
        width: 80%;
    }

    .miss-stat hr {
        width: 90%;
    }

    .tact-found-h1 {
        font-size: 38px;
    }

    .abt-boxes {
        display: block;
    }

    .img-box img {
        width: 100%;
        padding-bottom: 30px;
        border: none;
    }

    .bio-box {
        width: 350px;
        margin: auto;
    }

    .bio-box p {
        font-size: 15px;
    }

    .bio-box h1 {
        font-size: 25px;
    }

    .vision-h1 {
        font-size: 40px;
    }

    .bel-cel-h1 {
        font-size: 38px;
    }

    .bel-cel-align {
        display: flex;
    }
    

    .btn-abt2 {
        padding: 15px 25px;
        font-size: 15px;
        margin: auto;
    }
    
}