/****** Header Section ******/

.header-section-gv {
    min-height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url(./Images/give.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
}

.title-gv {
    font-size: 55px;
    color: white;
    margin-top: 14%;
    text-align: center;
}
.give-align {
    text-align: center;
}

.btn-gv-1 {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 500px;
    padding: 20px 45px;
    font-size: 20px;
    background: transparent;
    cursor: pointer;
    margin: 40px;
}

.btn-gv-1:hover {
    border: 1px solid red;
    background: red;
    transition: 1s;
}

/****** Donation  Section ******/

.donation-gv {
    margin-bottom: 40px;
}

.donation-gv h1 {
    text-align: center;
    font-size: 45px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.don-texts {
    text-align: center;
}

.donation-gv p {
    width: 80%;
    font-size: 20px;
    padding-bottom: 20px;
    margin: auto;
}

/****** Donation card Section ******/

.don-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.don-card {
    width: 345px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin: 35px;
    transition: 0.5s ease;
    cursor: pointer;
    border-color: black;
    border-width: 5px;
    border-style: solid;
    background-color: black;
}

.don-card:hover {
    transform: scale(1.1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.don-row h2 {
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center;
    color: white;
}

.don-btn-align {
    text-align: center;
}

.don-row button, .don-row a {
  display: inline-block;
  text-decoration: none;
  background-color: black;
  color: white;
  border: 1px solid white;
  border-radius: 500px;
  padding: 20px 45px;
  font-size: 15px;
  cursor: pointer;
}

.btn-card-gv:hover {
  border: 1px solid white;
  background: white;
  transition: 1s;
  color: black;
}

.don-card img {
    width: 100%;
    height: 50%;
}

.donation-cards-gv {
    margin-bottom: 40px;
}

/****** Responsiveness ******/

@media screen and (max-width: 800px) {
    .header-section-gv {
        min-height: 80vh;
    }

    .title-gv {
        font-size: 35px;
        margin-top: 40%;
        margin-bottom: 20px;
        
    }

    .btn-gv-1 {
        padding: 15px 25px;
        font-size: 15px;
    }

    .donation-gv h1 {
        font-size: 35px;
    }

    .donation-gv p {
        font-size: 15px;
        width: 100%;
    }

    .don-row h2 {
        font-size: 20px;
    }

    .don-row button, .don-row a {
        padding: 15px 25px;
        font-size: 15px;
    }

}