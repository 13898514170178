.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 1240px;
    background-color: #fff;
    border-radius: 10px;
}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    display: inline-block;
    text-decoration: none;
    color: black;
    border: 1px solid black;
    border-radius: 500px;
    padding: 10px 35px;
    font-size: 15px;
    cursor: pointer;
}

.close-btn:hover {
    border: 1px solid black;
    background: black;
    transition: 1s;
    color: #fff;
}

.popup-inner img {
    position: absolute;
    margin-left: 4%;
}

.popup-inner h2 {
    text-align: left;
    padding-left: 58.7%;
    
}

.popup-inner p {
    padding-top: 10px;
    width: 35%;
    margin-left: 59%;
    padding-bottom: 10px;
    text-align: left;
}

/****** Responsiveness ******/

@media screen and (max-width: 800px) {
    .popup-inner {
        height: 80vh;
        width: 90%;
    }

    .popup-inner img {
        width: 75%;
        margin-left: 0%;
    }

    .popup-inner h2 {
        position: relative;
        padding-top: 65px;
        margin: auto;
        margin-left: -60%;
    }

    .popup-inner p {
        font-size: 15px;
        margin-left: 0%;
        width: 100%;
    }

    .close-btn {
        position: relative;
        margin-top: 160%;
        margin-right: 35%;
    }

    
}