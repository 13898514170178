/****** Home Section ******/

.header-sect {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url(./Images/home.jpeg);
    background-position: center;
    background-size: cover;
    position: relative;
}

.container {
  padding: 10px 5%;
}

.title-hm {
    font-size: 55px;
    margin-top: 14%;
    color: white;
    text-align: center;
}

.home-btns-nav {
  text-align: center;
  
}

.home-btn {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 500px;
    padding: 20px 45px;
    font-size: 20px;
    background: transparent;
    cursor: pointer;
    margin: 40px;
}

.home-btn:hover {
    border: 1px solid red;
    background: red;
    transition: 1s;
}

  /****** Core Values Section ******/

  .core-vals-h1 {
    text-align: center;
    font-size: 45px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

 .core-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
 }

 .card {
    width: 300px;
    border-radius: 8px;
    overflow: hidden;
    background-color: black;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin: 20px;
    transition: 0.5s ease;
    cursor: pointer;
 }

 .card img {
    width: 100%;
    height: 50%;
 }

 .core-cards h2 {
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center;
    color: white;
 }

.core-cards p {
    color: white;
    font-size: 18px;
    padding: 8px 16px;
    border-radius: 4px;
    text-align: center;
}

.card:hover {
    transform: scale(1.1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.core-val-btn {
    display: inline-block;
    text-decoration: none;
    color: red;
    border: 1px solid red;
    border-radius: 500px;
    padding: 20px 45px;
    font-size: 20px;
    cursor: pointer;
}

.core-val-btn:hover {
    border: 1px solid red;
    background: red;
    transition: 1s;
    color: #fff;
}

.core-val-btns {
    padding-top: 20px;
    padding-bottom: 40px;
    text-align: center;
}

/****** Videos Section ******/

.title-vids {
    text-align: center;
    font-size: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.video-carousel {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 50px; 
}

.fa-arrow-right {
    position: absolute;
    color: black; 
    font-size: 24px; 
    margin-left: 30px;
}

.fa-arrow-left {
    position: absolute;
    color: black; 
    font-size: 24px; 
    margin-left: -70px;
}

.video-element {
    width: 100%;
    height: auto;
}

.slick-dots li button {
    width: 12px;
    height: 12px; 
    border-radius: 50%; 
    background-color: #ccc; 
}


/****** Responsiveness ******/

@media screen and (max-width: 800px) {
  .header-sect {
    height: 80vh;
  }

  .title-hm {
    font-size: 35px;
    margin-top: 40%;
    margin-bottom: 20px;
  }

  .home-btn {
    padding: 15px 35px;
    font-size: 15px;
    margin: 12px;
  }

  .core-vals-h1 {
    font-size: 40px;
  }

  .core-cards h2 {
    font-size: 30px;
  }

  .core-cards p {
    font-size: 18px;
  }

  .core-val-btn {
    padding: 15px 35px;
    font-size: 15px;
  }

  .title-vids h1 {
    font-size: 35px;
    text-align: center;
  }

  .fa-arrow-right { 
    margin-left: -65%;
    font-size: 20px;
  }

  .fa-arrow-left {
    margin-left: -130%;
    font-size: 20px;
  }

}